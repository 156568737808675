import React from 'react'
import {Link, useStaticQuery} from 'gatsby'
import _ from 'lodash'
import useWindowSize from "./useWindowSize";

const Menu = ({active}) => {
    const data = useStaticQuery(graphql`
        query  {
          site {
            siteMetadata {
              prize_info {
                color
                key
                label
              }
            }
          }
        }
    `)


    const {width} = useWindowSize();
    const sp = width < 640;
    const prize_info = data.site.siteMetadata.prize_info

    const VerticalMenu = () => (
        <div className='ui vertical fluid tabular menu'>
            {prize_info.map(prize => {
                const className = `item ${prize['color']} ${active === prize['key'] ? 'active' : ''}`
                return <Link to={`/${prize['key']}`} className={className} key={prize['key']}>{prize['label']}</Link>
            })}
        </div>
    )

    return (
        <div className={`${sp ? 'sixteen' : 'three'} wide column`}><VerticalMenu/></div>
        // sp ? null : <VerticalMenu/>
    )
}

export default Menu

import React from 'react'
import BookItem from './BookItem'
import _ from "lodash";

const BookTable = ({books, prize_name}) => {
    const keys = _.reverse(_.keys(books));

    const THead = () => (
        <thead>
        <tr>
            <th></th>
            {prize_name === '本屋大賞' ? <th></th> : null}
            <th><h2>{prize_name}</h2></th>
        </tr>
        </thead>
    );

    const TBody = () => {
        const body = keys.map(year => {
            let rows = [];
            for (let i = 0; i < books[year].length; i++) {
                rows.push(
                    <tr key={books[year][i]['book']}>
                        {i === 0 ?
                            <td rowSpan={books[year].length} style={{verticalAlign: 'top'}}><h4>{year}</h4></td> : null}
                        {prize_name === '本屋大賞' ? <td><h4>{books[year][i]['rank']}</h4></td> : null}
                        <td style={{paddingLeft: '0'}}>
                            <BookItem book={books[year][i]}/>
                        </td>
                    </tr>
                )
            }
            return rows
        });
        return <tbody>{body}</tbody>;
    }

    return (
        <table className='ui padded very basic table'>
            <THead/>
            <TBody/>
        </table>
    )
}

export default BookTable
import React from 'react'
import {Link} from 'gatsby'

const BookItem = ({book}) => {
    return (
        <Link to={`/book/${book['mongodb_id']}`}>
            <div className='ui divided items'>
                <div className="item">
                    <div className="ui tiny image">
                        <img alt={'book cover'} src={book['image_path'] ?
                                `/images/${book['prize_name']}/${book['image_path']}` : '/images/now_printing.jpg'}/>
                    </div>
                    <div className="middle aligned content">
                        <div className="ui sub header" style={{margin: 0}}>
                            <h4>{book['book']}</h4>
                        </div>
                        <div className='description'>
                            <span>{book['author']}</span>
                        </div>
                        <div className='meta' style={{fontSize: '13px'}}>{book['description'] ? book['description'].slice(0, 100) + '...' : null}</div>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default BookItem;